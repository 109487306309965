// Load Styles
import '../scss/main.scss';

import $ from 'jquery';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

import 'select2';
import 'slick-carousel';
import 'jquery-match-height';

$(function () {
  //## jQuery
  // const winH = $(window).height();
  // console.log(`Window height: ${winH}`);
  // const winW = $(window).width();
  // console.log(`Window width: ${winW}`);
  //## Slick
  if ($.fn.slick) {
    $("#banners.slider").slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      fade: true,
      cssEase: 'linear',
      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     dots: false,
        //   },
        // },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          },
        },
      ],
    });
    $(".platform-slider").slick({
      dots: true,
      arrows: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      // variableWidth: true,
      fade: true,
      cssEase: 'linear',
      responsive: [
        // {
        //   breakpoint: 1024,
        //   settings: {
        //     dots: false,
        //   },
        // },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
          },
        },
      ],
    });
  }

  //## select2
  var select2Init = function () {
    if ($.fn.select2) {
      // $.fn.select2.defaults.set('theme', 'bootstrap');
      // $('.select2').select2();

      $('.select2').select2({
        theme: "bootstrap-5",
        width: '100%',
        placeholder: function () {
          $(this).data('placeholder');
        },
        allowClear: true
      });
    }
  };

  select2Init();

  //## matchHeight
  var matchHeightInit = function () {
    if ($.fn.matchHeight) {
      $('.equal-height').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
      });
    }
  };

  matchHeightInit();
});

